// challenger-app/src/services/challengeService.ts
import axios from 'axios';
import { API_URL,getAuthConfig,saveChallenger } from '../config/config';
import { getToken } from 'firebase/messaging';
import { messaging,PUBLIC_VAPID_KEY } from '../auth/firebaseConfig'; 

export const getChallenges = async (page:any,category:any,difficulty:any) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const category_search =  category ?  '&category=' +  category : '';
    const difficulty_search =  difficulty ?  '&difficulty=' +  difficulty : '';

    const response = await axios.get(API_URL + `/challenge/feed?page=${page}` + category_search + difficulty_search, {
      ...getAuthConfig()
    });
    //console.log(response)
    if(response.status === 200 && response.data.status === 'success'){
        //console.log(response.data);
      return response.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const getMyChallenges = async (page:any, status:string | null,category:any,difficulty:any) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const status_search =  status ?  '&status=' +  status : '';
    const category_search =  category ?  '&category=' +  category : '';
    const difficulty_search =  difficulty ?  '&difficulty=' +  difficulty : '';

    const response = await axios.get(API_URL + `/challenger/mine?page=${page}` + status_search  + difficulty_search + category_search, {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
        //console.log(response.data);
      return response.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const getPublishedChallenges = async (page:any,category:any, difficulty:any) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const category_search =  category ?  '&category=' +  category : '';
    const difficulty_search =  difficulty ?  '&difficulty=' +  difficulty : '';

    const response = await axios.get(API_URL + `/challenger/published?page=${page}` + category_search + difficulty_search, {
      ...getAuthConfig()
    });
    //console.log(response)
    if(response.status === 200 && response.data.status === 'success'){
      //  console.log(response.data);
      return response.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const getNotifications = async (page:any) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    
    const response = await axios.get(API_URL + `/challenger/get_notifications_by_challenger_id?page=${page}`, {
      ...getAuthConfig()
    });
    //console.log(response)
    if(response.status === 200 && response.data.status === 'success'){
      //  console.log(response.data);
      return response.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const getChallengeDetails = async (_id: string) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.get(API_URL + '/challenge/info?_id='+_id, {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
       //console.log(response.data);
      return response.data.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const processInvitation = async (token: string) => {
  try {
   // console.log("token" , token)
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.get(API_URL + '/challenge/invitation?invitetoken='+token, {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
     //  console.log(response.data);
      return response.data.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.log( error);
    throw error;
  }
};

export const getchallengevideos = async (_id: string) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.get(API_URL + '/challenge/getchallengevideos?_id='+_id, {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
      // console.log(response.data);
      return response.data.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const getOtherChallengerProfile = async (_id: string) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    //console.log(_id)
    const response = await axios.get(API_URL + '/challenger/get_other_challenger_profile?_id='+_id, {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
       //console.log(response.data);
      return response.data.data;
    }else{
      return null;
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const takeChallenge = async (_id: string,start_date: Date | null) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    
    const response = await axios.post(API_URL + '/challenge/take', { _id,start_date }, {
      ...getAuthConfig()
    });
   // console.log(response);
    if(response.status === 200 && response.data.status === 'success'){
     // console.log(response.data);
      // Save the auth_token as a cookie
      if (response.data.data.challenger && response.data.data.challenger.jwtToken) {
        await saveChallenger(response.data.data.challenger);
      }
      return response.data.data;
    }else{
      throw new Error('Failed to take challenge');
    }
  } catch (error) {
    console.error('Error taking challenge:', error);
    throw error;
  }
};

export const removeChallenge = async (_id: string) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    
    const response = await axios.post(API_URL + '/challenge/remove', { _id }, {
      ...getAuthConfig()
    });
    //console.log(response);
    if(response.status === 200 && response.data.status === 'success'){
      //console.log(response.data);
      // Save the auth_token as a cookie
     
      return response.data.data;
    }else{
      throw new Error('Failed to remove challenge');
    }
  } catch (error) {
    console.error('Error removing challenge:', error);
    throw error;
  }
};

export const getChallengerByToken = async (token: string) => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.get(API_URL + '/challenger/info', {
      ...getAuthConfig()
    });
    
    if(response.status === 200 && response.data.status === 'success'){
       //console.log(response.data);
      return response.data.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const get_challenger_statistics = async () => {
  try {
    if (!API_URL ) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.get(API_URL + '/challenger/get_challenger_statistics', {
      ...getAuthConfig()
    });
    //console.log(response.data);
    if(response.status === 200 && response.data.status === 'success'){
       
      return response.data.data;
    }else{
      throw new Error('Failed to fetch challenges');
    }
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const signup = async (username: string,email: string, password: string) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/signup', {username, email, password });

    if(response.status === 200 && response.data.status === 'success'){
      await saveChallenger(response.data.data.challenger);
      return response.data.data;
    }else{
      throw new Error('Failed to signup');
    }
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const savingchallenger = async (user:any) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/getin', user,{
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
     // console.log("re")
      // console.log(response.data.data.challenger)
      await saveChallenger(response.data.data.challenger);
      if(response.data.data.challenger?.email){
        const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });
        //console.log(token)
        await savingchallengertoken({fcm_device_token : token, email: response.data.data.challenger.email});
      }
      return response.data.data;
    }else{
      throw new Error('Failed to signup');
    }
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const savingchallengertoken = async (user:any) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/getin', user,{
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
      //await saveChallenger(response.data.data.challenger);
      return response.data.data;
    }else{
      throw new Error('Failed to signup');
    }
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const login = async (email: string, password: string) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/login',  { email, password }, {
      ...getAuthConfig()
    });
    console.log(response);
    if(response.status === 200 && response.data.status === 'success'){
      await saveChallenger(response.data.data.challenger);
      return response.data.data;
    }else{
      throw new Error('Failed to login');
    }
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const updateChallenger = async (challenger: any) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/update', challenger, {
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
      await saveChallenger(response.data.data.challenger);
      return response.data.data;
    }else{
      throw new Error('Failed to update challenger');
    }
  } catch (error) {
    console.error('Error updating challenger:', error);
    throw error;
  }
};

export const updateChallengerChallenge = async (challengeChallenger: any) => {
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenger/updatechallenge', challengeChallenger, {
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
      //await saveChallenger(response.data.data);
      return response.data.data;
    }else{
      throw new Error('Failed to update challenger');
    }
  } catch (error) {
    console.error('Error updating challenger:', error);
    throw error;
  }
};

export const createChallengeByChallenger = async (challenge:any)=>{
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenge/create', challenge, {
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
     // console.log(response.data)
      //await saveChallenger(response.data.data);
      return response.data.data;
    }else{
      throw new Error('Failed to create challenge');
    }
  } catch (error) {
    console.error('Error creating challenge:', error);
    throw error;
  }
}

export const editChallengeByChallenger = async (challenge:any)=>{
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenge/update', challenge, {
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
      //await saveChallenger(response.data.data);
      return response.data.data;
    }else{
      throw new Error('Failed to edit challenge');
    }
  } catch (error) {
    console.error('Error editing challenge:', error);
    throw error;
  }
}

export const deleteChallengeByChallenger = async (challenge_id:string)=>{
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    const response = await axios.post(API_URL + '/challenge/delete', {challengeId:challenge_id}, {
      ...getAuthConfig()
    });
    if(response.status === 200 && response.data.status === 'success'){
      //await saveChallenger(response.data.data);
      return response.data;
    }else{
      throw new Error('Failed to delete challenge');
    }
  } catch (error) {
    console.error('Error delete challenge:', error);
    throw error;
  }
}

export const deleteAccount = async ()=>{
  try {
    if (!API_URL) {
      throw new Error('API_URL is not defined');
    }
    console.log(await getAuthConfig())
    const response = await axios.post(API_URL + '/challenger/delete',{}, {
      ...getAuthConfig()
    });
    console.log("response")
    console.log(response)
    if(response.status === 200 && response.data.status === 'success'){
      //await saveChallenger(response.data.data);
      return response.data;
    }else{
      throw new Error('Failed to delete challenge');
    }
  } catch (error) {
    console.error('Error delete challenge:', error);
    throw error;
  }
}

export const uploadHowToFiles = async (filedata:any,randomFileName:string)=>{
    
    // Get the file name and type
    const fileName = randomFileName;
    const fileType = filedata.type;
    try {
        // Get the pre-signed URL from the backend
        const response = await axios.get(API_URL + '/get_presigned_url', {
          ...getAuthConfig(),
          params: {
            fileName: fileName,
            fileType: fileType
          }
        });
        const { url } = response.data;
        // Upload the file directly to S3 using the pre-signed URL
        const result = await axios.put(url, filedata, {
            headers: {
                'Content-Type': fileType,
            },
        });
        
        return result;
        // Clear any previous errors
    } catch (error) {
      console.log(error)
        console.error('Error uploading file:', error);
        throw error;
    }
}

export const uploadUserFiles = async (filedata:any,randomFileName:string)=>{
    
    // Get the file name and type
    const fileName = randomFileName;
    const fileType = filedata.type;
    try {
        // Get the pre-signed URL from the backend
        const response = await axios.get(API_URL + '/get_user_presigned_url', {
          ...getAuthConfig(),
          params: {
            fileName: fileName,
            fileType: fileType
          }
        });
        const { url } = response.data;
        // Upload the file directly to S3 using the pre-signed URL
        const result = await axios.put(url, filedata, {
            headers: {
                'Content-Type': fileType,
            },
        });
        
        return result;
        // Clear any previous errors
    } catch (error) {
      console.log(error)
        console.error('Error uploading file:', error);
        throw error;
    }
}

export const getcountries = async() =>{
  try {
    
    const response = await fetch('https://raw.githubusercontent.com/hninnushwe-sankabar/country/refs/heads/main/country.json');
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
}

export const getUserLocation = async() =>{
  let country = null;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        // Call the function to get the country based on the user's location
        country = getCountryByCoordinates( latitude, longitude);
        
      },
      (error) => {
        console.error('Error getting location:', error);
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
  return country;
}

export const getCountryByCoordinates = async ( latitude:any, longitude:any) =>{
  const countries = await getcountries();
  for (const country of countries) {
    if (
      latitude >= country.latitudeRange.min &&
      latitude <= country.latitudeRange.max &&
      longitude >= country.longitudeRange.min &&
      longitude <= country.longitudeRange.max
    ) {
      return {name: country.name,language: country.languageCode};
    }
  }
  return null; // Return null if no country is found
}


export const translateText = async (text:string) => {
 
  try {
    const locationdata:any = await getUserLocation();
    if(locationdata){
      const languageCode = locationdata.languageCode[0];
      const url = 'https://libretranslate.com/translate';
       const response = await axios.post(url, {
        q: text,
        source: 'auto',
        target: languageCode,
        format: 'text'
      });
      return response.data.translatedText;
    }else{
      return null;
    }
   
  } catch (error) {
    console.error('Error translating text:', error);
    return null;
  }
}