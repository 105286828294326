export const getStatusColor = (status: string | null) => {
    switch (status) {
      case 'started':
        return 'primary';
      case 'ended':
        return 'success';
      default:
        return 'default';
    }
  };

export  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty?.toLowerCase()) {
      case 'easy':
        return 'success';
      case 'medium':
        return 'warning';
      case 'hard':
        return 'error';
      default:
        return 'default';
    }
  };

  export const extractYouTubeID =async (url:string) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:shorts\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null; // Return the video ID or null if not found
};


export const getMediaType = (url: string): 'image' | 'video' | null => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
  const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.mkv'];

  const lowerCaseUrl = url?.toLowerCase();

  // Check for image extensions
  for (const ext of imageExtensions) {
      if (lowerCaseUrl.endsWith(ext)) {
          return 'image';
      }
  }

  // Check for video extensions
  for (const ext of videoExtensions) {
      if (lowerCaseUrl.endsWith(ext)) {
          return 'video';
      }
  }

  // If no valid extension is found, return null
  return null;
};

export const isValidMediaUrl = (url: string): boolean => {
  const mediaType = getMediaType(url);
  return mediaType !== null; // Returns true if it's either an image or video
};

export const getMediaTypeFromFilename = (filename: string): 'image' | 'video' | null => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
  const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.mkv'];

  const lowerCaseFilename = filename?.toLowerCase();

  // Check for image extensions
  for (const ext of imageExtensions) {
      if (lowerCaseFilename.endsWith(ext)) {
          return 'image';
      }
  }

  // Check for video extensions
  for (const ext of videoExtensions) {
      if (lowerCaseFilename.endsWith(ext)) {
          return 'video';
      }
  }

  // If no valid extension is found, return null
  return null;
};

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const progressCalculation =async (progress_data_length:number,end_date:Date,start_date:Date)=>{
  return Math.round((progress_data_length / ((new Date(end_date).getTime() - new Date(start_date).getTime()) / (24 * 60 * 60 * 1000))) * 100);
}

export const checkVideoDuration = (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = () => {
          const duration = videoElement.duration; // Get the duration in seconds
          resolve(duration <= 30); // Resolve true if duration is 30 seconds or less
      };

      videoElement.onerror = () => {
          reject(new Error('Error loading video metadata.'));
      };

      // Load the video metadata
      videoElement.load();
  });
};


export const categoryList = [
  { "text": "Fitness", "value": "fitness" },
  { "text": "Nutrition", "value": "nutrition" },
  { "text": "Wellness", "value": "wellness" },
  { "text": "Reading", "value": "reading" },
  { "text": "Skill Development", "value": "skill_development" },
  { "text": "Goal Setting", "value": "goal_setting" },
  { "text": "Time Management", "value": "time_management" },
  { "text": "Decluttering", "value": "decluttering" },
  { "text": "Habit Tracking", "value": "habit_tracking" },
  { "text": "Art", "value": "art" },
  { "text": "Writing", "value": "writing" },
  { "text": "DIY Projects", "value": "diy_projects" },
  { "text": "Volunteer", "value": "volunteer" },
  { "text": "Kindness", "value": "kindness" },
  { "text": "Family", "value": "family" },
  { "text": "Education", "value": "education" },
  { "text": "Creative", "value": "creative" },
  { "text": "Health", "value": "health" },
  { "text": "Mindfulness", "value": "mindfulness" },
  { "text": "Lifestyle", "value": "lifestyle" },
  { "text": "Social", "value": "social" },
  { "text": "Cooking", "value": "cooking" },
  { "text": "Productivity", "value": "productivity" },
  { "text": "Sustainability", "value": "sustainability" },
  { "text": "Travel", "value": "travel" },
  { "text": "Hobbies", "value": "hobbies" },
  { "text": "Self-Care", "value": "selfcare" },
  { "text": "Technology", "value": "technology" },
  { "text": "Music", "value": "music" },
  { "text": "Nature", "value": "nature" },
];

