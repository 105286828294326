import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Typography, Grid, Card, CardContent, Chip, Box, Tabs, Tab, Grid2, Button, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { getNotifications, getPublishedChallenges } from '../services/challengeService';
import { getStatusColor, getDifficultyColor, categoryList } from '../utils/siteHelpers';
import ChallengeCard from './ChallengeCart';
import {ChallengeInterface} from '../utils/ObjectInterface';
import { API_CHALLEXA_IMAGE_URL } from '../config/config';

function ExpandableCard(myitem:any) {
  const eachitem = myitem.eachitem;
  const [expanded, setExpanded] = useState(false);
  
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Card>
      <CardContent   sx={{ flexGrow: 1, mb:-2 }}>
            <Box sx={{ display: 'flex',alignItems: 'center'}}>
            <Avatar
                  alt="Profile Picture"
                  src={eachitem?.photo || API_CHALLEXA_IMAGE_URL}
                  sx={{ width: 50, height: 50, mr:1 }}
                />
              <Box>
              <Typography variant="body2" >
                  {expanded ? eachitem?.description : `${eachitem?.description.slice(0, 50)}...`}
                  <span 
                    onClick={toggleExpanded} 
                    style={{ color: 'blue', cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    {expanded ? ' Show less' : ' See more'}
                  </span>
                </Typography>
                <Typography variant="body4" >
                
                  {new Date(eachitem?.created_date).toLocaleDateString()}
                </Typography> 
              </Box>
            </Box>
              
            </CardContent>
    </Card>
  );
}

const MyChallengesPage: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pageType, setPageType] = useState('creator');
  const [showText, setShowText] = useState(false); // New state for text visibility
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); 
  const [nextpage, setNextPage] = useState(null); 
  
   // Function to throttle the scroll event
   const throttle = (callback:any, limit:any) => {
    let lastCall = 0;
    return function (...args: any) {
      const now = Date.now();
      if (now - lastCall < limit) return; // Prevent the function call if it's too soon
      lastCall = now;
      return callback(...args);
    };
  };

  
  useEffect(() => {
    if (loading) return; // Ensure we don't fetch if already loading
    //console.log("Fetching challenges for page:", page);
    try {
      setLoading(true);
      getNotifications(page).then((data) => {
        console.log(data)
        setNotifications((prev) => [...prev, ...data.data]);
        setNextPage(data.data.length < data.limit ? null : data.page + 1);
        setLoading(false);
      });  
      
    } catch (error) {
      console.error('Failed to fetch challenges:', error);
    } finally {
      setLoading(false); // Reset loading state in finally block
    }
    /* const timer = setTimeout(() => {
        setShowText(true); // Show text after 10 seconds
      }, 1000);

    return () => clearTimeout(timer); // Cleanup timer on unmount */
    }, [page]);
   
    

  // Scroll event listener
  useEffect(() => {
    const handleScroll = throttle(() => {
      //console.log("1 > " , window.innerHeight + document.documentElement.scrollTop + 50 )
      //console.log("2 > " , document.documentElement.offsetHeight)
      if (
        window.innerHeight + document.documentElement.scrollTop + 50 >=
        document.documentElement.offsetHeight
      ) {
        if (!loading && nextpage) {
          //console.log("Fetching page:", nextpage);
          setPage(nextpage);
        }
      }
    }, 200); // Adjust the limit to your needs (200ms in this example)

    // Set up the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, nextpage]);

  
  
  
  return (
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
    :
    <Container maxWidth="lg"  >
      <Tabs  
        sx={{ position: "fixed", top: 55.5, left: 0, right: 0, background: "black", zIndex: 1100 }}
        value={0} centered>
          <Tab 
            label='Notifications'
          />
        </Tabs>
      <Grid2 container >
        {notifications.map((eachitem,index) => (
           <ExpandableCard key={index} eachitem={eachitem} />
          
          
        ))}
      </Grid2>
    </Container>
  );
};

export default MyChallengesPage;