import React, { useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { TextField, Button, Box, Alert, Typography, Container } from '@mui/material';
import { deleteAccount } from '../services/challengeService';
import { getAuthToken, getChallenger, removeAuthToken, removeChallenger,BrandText,MountainText } from '../config/config';

function DeleteForm() {
  const navigate = useNavigate();
  const [deleteokay, setDeleteOkay] = useState(false);
  const [deletenotokay, setDeleteNotOkay] = useState(false);
  const [showTextData, setShowTextData] = useState("");
  const [enteremail, setEnterEmail] = useState('');
  const handleDelete = async () => {
    const challenger_  = await getChallenger();
    console.log(enteremail)
    console.log(challenger_)
    if(challenger_.email === enteremail){
      const success_ = await deleteAccount();
      
      if(success_){
        setDeleteOkay(true);
        removeAuthToken();
        removeChallenger();
        navigate('/');
        window.location.reload();
      }else{
        setDeleteNotOkay(true);
        setShowTextData("Your account is failed to delete. Try again!");
      }
    }else{
      setDeleteNotOkay(true);
      setShowTextData("Email is wrong.");
    }
    
    
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
         <Box>
            <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1, textAlign:"center" , mb: 3}} justifyContent="center" >
            Enter your registered email to confirm delete.
            </Typography>
          </Box>
            <Box component="form" >
              <TextField onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { name, value } = e.target;
                        setEnterEmail(value);
                }} 
              label="Email" type="text" fullWidth margin="normal" required />
              <Button onClick={handleDelete} variant="contained" fullWidth sx={{ mt: 2 }}>
                Delete
              </Button>
              {deleteokay && <Alert severity="success">Your account is successfully deleted. See you again!</Alert>}
              {deletenotokay && <Alert severity="error">{showTextData}</Alert>}
              
            </Box>

    </Container>
   
  );
}

export default DeleteForm;