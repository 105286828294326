import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid, TextField, Tab, Tabs, CardContent, Card, CardHeader, Chip, CircularProgress, IconButton, Avatar, Paper, Grid2 } from '@mui/material';
import {getOtherChallengerProfile} from '../services/challengeService';
import { getStatusColor } from '../utils/siteHelpers';
import VideoCarousel from './VideoCarousel';
import { BrandText } from '../config/config';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';


const ChallengerProfilePage: React.FC = () => {
  const [challenger, setChallenger] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('_id');
  const pagetype = searchParams.get('p');
  const [tabValue, setTabValue] = useState(0);
  const [videoUrl, setVideoURL] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  
  useEffect( () => {
    fetchChallenger();
    
  }, []);

  const fetchChallenger = async () => {
    try {
      setLoading(true);
      if(id){
        const data = await getOtherChallengerProfile(id);
        if(data){
          setChallenger(data.challenger);
          //console.log(data.challenger);
          setHighlight(data.challenger); // Call setHighlight after it's defined
          
        }
        setLoading(false);
      }else{
        setLoading(false);
      }
      
    } catch (error) {
      console.error('Error fetching challenger:', error);
    }
  };

  const  getRandomIndex = (length:number)=> {
      
      // Determine maxIndex using a logarithmic function, or any other formula
      const maxIndex = Math.min(Math.floor(Math.log2(length)), length - 1);

      // Generate a random index between 0 and maxIndex
      return Math.floor(Math.random() * (maxIndex + 1));
  }


  const setHighlight = async (currentChallenger: any) => {
    setVideoURL([]); // Reset video URLs to avoid duplicates
    if (currentChallenger.challenge_list.length > 0) {
      const newVideoUrls: string[] = []; // Temporary array to hold new video URLs
      for (let i = 0; i < currentChallenger.challenge_list.length; i++) {
        const current = currentChallenger.challenge_list[i].progress_data;
        if (current.length > 0) {
          
          const url = current[current.length - 1];
          //console.log(url);
          if (url && !newVideoUrls.includes(url)) { // Check for duplicates
            newVideoUrls.push(url); // Add to temporary array
          }
          
        }
      }
      const sources =  currentChallenger.challenge_list.map((each:any)=>  each.progress_data).filter((each:any)=> each && each.length > 0);
      sources.forEach((source:any) => {
          const sourceLength = source.length;

          // Determine how many items to take from this source, e.g., half of its length, rounded up
          const itemsToAdd = Math.ceil(sourceLength / 2);

          // Add items from this source to newVideoUrls, without duplicates
          let count = 0;
          for (let i = 0; i < sourceLength && count < itemsToAdd; i++) {
              const url = source[i];
              if (url && !newVideoUrls.includes(url)) {
                  newVideoUrls.push(url);
                  count++;
              }
          }
      });
      // Update state with unique video URLs
      setVideoURL((prev) => [...prev, ...newVideoUrls]);
      //console.log(newVideoUrls);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };


  return (
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
    :
    <>
    
    {challenger ? (
      <>
      <Tabs  
        sx={{ position: "fixed", top: 55.5, left: 0, right: 0, background: "black", zIndex: 1100 }}
        value={0} centered>
          <Tab 
            label={<span ><span style={{color:'White'}}>Consistency Rank &nbsp;</span>
            [&nbsp;{videoUrl && videoUrl.length >= 10 ? 'Grandmaster' :
            videoUrl && videoUrl.length >= 7 ? 'Master' :
            videoUrl && videoUrl.length >= 4 ? 'Expert' :
            videoUrl && videoUrl.length >= 1 ? 'Novice' :
            'Beginner'}
                           &nbsp;] </span> } 
          />
        </Tabs>
        <Container maxWidth="lg" sx={{ mt: 2, position: 'relative', minHeight: '100vh' }}>
        
        <Grid2 container  spacing={2}>
        
        <Grid2  container size={4} >
          <Box
            sx={{
              width: 104, // Avatar size (100) + border thickness (2) * 2
              height: 104,
              borderRadius: '50%',
              border: '2px solid', // Thickness of the border
              borderColor: 'primary.main', // You can change this to any color
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
              cursor: 'pointer',
            }}
          >
            <Avatar
              alt="Profile Picture"
              src={challenger.challenger_photo}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
        </Grid2>
        <Grid2 container direction="column" size={8}>
            <Typography variant="subtitle1" component="div" sx={{ display: 'flex' }}>
              <strong>{challenger.challenger_username}</strong>
            </Typography>
            <Typography variant="body2" component="div" sx={{ display: 'flex' }}>
              {challenger.bio}
            </Typography>
            <Typography variant="body3">
              Completed: {challenger.completed || 0} . Ongoing: {challenger.ongoing || 0}
            </Typography>
        </Grid2>

      </Grid2>

        <Card sx={{mt:2}}>
          <CardContent>
          <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1 , mb:2}} >
          Highlighted videos
                        </Typography>
          { videoUrl.length > 0 && <VideoCarousel videoUrls={videoUrl} /> }
          </CardContent>
        </Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
          <Tabs value={tabValue}  onChange={handleTabChange} aria-label="challenge tabs" sx={{ width: '100%' }}>
            <Tab label="Challenges" sx={{ width: '100%' }} />
          </Tabs>
        </Box>
        <Box role="tabpanel"  hidden={tabValue !== 0}>
          { challenger?.challenge_list && (
            <Box sx={{ mt: 2 }}>
              {challenger?.challenge_list.map((obj:any) => (
                <Card key={obj.challenge_id} sx={{ mb: 2 }}  onClick={() => navigate(`/challenge/info?_id=${obj.challenge_id}&p=${pagetype}`)} >
                  <CardContent sx={{ flexGrow: 1 }} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      
                        <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1 }}>
                        {obj.challenge_title}
                        </Typography>
                        <Chip
                          label={obj.status || ''}
                          color={getStatusColor(obj.status || null)}
                          size="small"
                          />
                      </Box>
                    <Typography variant="body2" gutterBottom>Progress: {obj.progress} %</Typography>
                    <Typography variant="body2" gutterBottom>Start Date: {new Date(obj.start_date).toLocaleDateString()}</Typography>
                    <Typography variant="body2" gutterBottom>End Date: {new Date(obj.end_date).toLocaleDateString()}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Box>
        </Container>
      </>
    ) : (
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Box>
          <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1, textAlign:"center" , mb: 3}} justifyContent="center" >
            Challenger is in private mode.
          </Typography>
        </Box>
      </Container>
    )}
    </>
  );
}

export default ChallengerProfilePage;
