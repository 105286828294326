import React, { useState } from 'react';
import { Snackbar, Box, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { uploadHowToFiles,createChallengeByChallenger } from '../services/challengeService';
import { useNavigate, useLocation } from 'react-router-dom';
import {ChallengeWithoutIDInterface,ChallengeObjectInterface} from '../utils/ObjectInterface';
import { generateRandomString, getMediaType,checkVideoDuration, categoryList } from '../utils/siteHelpers';
import {API_HOWTO_URL_PREFIX} from '../config/config'


const CreateChallenge: React.FC = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [visibility, setVisibility] = useState('private'); // Default visibility
  const [difficulty, setDifficulty] = useState('');
  const [durationValue, setDurationValue] = useState(1);
  const [durationUnit, setDurationUnit] = useState('week');
  const [exercises, setExercises] = useState<ChallengeObjectInterface[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [exerciseDescription, setExerciseDescription] = useState('');
  const [exerciseImageUrl, setExerciseImageUrl] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorPopup, setErrorPopup] = useState<string | null>(null); // Error state
  const [category, setCategory] = useState('');
 
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [file, setFile] = useState<File | null>(null); // File state for upload
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // Preview URL for the uploaded file
  const [previewType, setPreviewType] = useState<string | null>(null); // Preview URL for the uploaded file

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const uploadtype = await getMediaType(selectedFile?.name);
      if (uploadtype === 'video') {
        try {
            const isValidDuration = await checkVideoDuration(selectedFile);
            if (!isValidDuration) {
                setError('Video duration must be 30 seconds or less.');
                setFile(null); // Clear the file input
                setPreviewUrl(null); // Clear the preview URL
                setPreviewType(null); // Clear the preview type
            } else {
              setErrorPopup('Video must be under 30 second.');
                setFile(selectedFile);
                setPreviewUrl(URL.createObjectURL(selectedFile));
                setPreviewType(uploadtype); // Create a preview URL for the selected file
            }
        } catch (error) {
          setErrorPopup('Video must be under 30 second.');
            setError('Error loading video metadata.');
            console.error(error);
        }
    } else {
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setPreviewType(uploadtype)
    }
    
    }
  };

  const handleAddExercise = async () => {
    if (!exerciseDescription) {
      setError('Please provide an exercise description.');
      return;
    }
    
    if(file && previewType && previewUrl){
      const newExercise: ChallengeObjectInterface = {
        no: exercises.length + 1,
        object_data: [{ url: previewUrl, type: previewType,issaved: false , file}],
        description: exerciseDescription,
      };
  
      // Update the exercises state
      setExercises([...exercises, newExercise]);
      setExerciseDescription(''); // Clear the description
      setFile(null); // Clear the file input after adding exercise
      setPreviewUrl(null); // Clear the preview URL
      setOpenDialog(false);     // Create a new exercise object with the preview URL
    
    }// Close the dialog
  };

  const handleRemoveExercise = (index: number) => {
    const updatedExercises = exercises.filter((_, i) => i !== index);
    setExercises(updatedExercises);
  };

  const handleSubmitChallenge = async () => {
    let challenge: ChallengeWithoutIDInterface = {
      title,
      description,
      duration_text: durationValue + " " + durationUnit ,
      difficulty,
      duration_value: durationValue,
      duration_unit : durationUnit,
      visibility,category
    };

    setLoading(true); // Set loading state

    try {
      // Upload files for each exercise
      //console.log(exercises)
        challenge.challenge_object = await Promise.all(exercises.map(async (exercise: any,index)=>{
          exercise.no = index + 1;
          if ( exercise.object_data[0].file && !exercise.object_data[0].issaved && exercise.object_data[0].url ) {
            const randomFileName = generateRandomString(16) + '.' + exercise.object_data[0].file.name.split('.').pop();
            const uploadResult = await uploadHowToFiles(exercise.object_data[0].file,randomFileName); // Upload the file
            //console.log(uploadResult)
            if (uploadResult) {
              exercise.object_data[0].url = API_HOWTO_URL_PREFIX + randomFileName;
              exercise.object_data[0].issaved = true;
              //console.log(exercise.object_data[0])
            }
          }
          //console.log(exercise)
          return exercise;
        }));
      //console.log(exercises)
      /**/

      const response = await createChallengeByChallenger(challenge);
      //console.log(response)
      if(response){
        navigate(`/challenge/info?_id=${response._id}&p=creator`);
      }
    } catch (err) {
      console.error(err);
      setError('Failed to edit challenge. Please try again.');
    } finally {
      setLoading(false); // Reset loading state
    }
   };

  return (
    
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
:
    <Box sx={{ p: 3 }}>
        <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Your challenge is up now!
        </Alert>
      </Snackbar>
      <Typography variant="subtitle2" component="div" gutterBottom>Be a creator by creating a new challenge</Typography>
      <TextField
        label="Challenge Title"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Challenge Description"
        fullWidth
        margin="normal"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Difficulty</InputLabel>
        <Select
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
        >
          <MenuItem value="easy">Easy</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="hard">Hard</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Duration Value"
        type="number"
        fullWidth
        margin="normal"
        value={durationValue}
        onChange={(e) => setDurationValue(Number(e.target.value))}
      />
       <FormControl fullWidth margin="normal">
        <InputLabel>Duration Unit</InputLabel>
        <Select
          value={durationUnit}
          onChange={(e) => setDurationUnit(e.target.value)}
        >
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="year">Year</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {categoryList.map((cat,index) => (
                    <MenuItem key={index} value={cat.value}>
                        {cat.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Visibility</InputLabel>
        <Select
          value={visibility}
          onChange={(e) => setVisibility(e.target.value)}
        >
          <MenuItem value="public">Public</MenuItem>
          <MenuItem value="private">Private</MenuItem>
        </Select>
      </FormControl>
      {/* List of Exercises */}
      <Typography variant="subtitle2" component="div" gutterBottom sx={{ mt: 3 }}>
        Exercises
      </Typography>
      {exercises.map((exercise, index) => (
        <><Typography variant="body2" component="div" gutterBottom sx={{ mb: 1 }}>
          {exercise.no}. {exercise.description}
        </Typography><Box key={index} sx={{ position: 'relative', mb: 1 }}>
            {exercise.object_data[0].type === 'image' && <img src={exercise.object_data[0].url} alt="Exercise Preview" style={{ maxWidth: '100%', height: 'auto' }} />}
            {exercise.object_data[0].type === 'video' && <video src={exercise.object_data[0].url} autoPlay loop style={{ maxWidth: '100%', height: 'auto' }}></video>}
            <Button variant="contained" color="error" onClick={() => handleRemoveExercise(index)} sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
              x
            </Button>
          </Box></>
      ))}
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{ mt: 2 }}>
          Add Exercise
        </Button>
        <Button variant="contained" onClick={handleSubmitChallenge} sx={{ mt: 2, ml: 2 }}>
          Create
        </Button>

      </Box>
      
      {/* Dialog for Adding Exercise */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Exercise</DialogTitle>
        <DialogContent>
        {errorPopup && <Alert severity="error">{errorPopup}</Alert>} {/* Error message display */}

          <TextField
            label="Exercise Description"
            fullWidth
            margin="normal"
            value={exerciseDescription}
            onChange={(e) => setExerciseDescription(e.target.value)}
          />
          <input type="file" onChange={handleFileChange} />
          {previewUrl && 
            <>
            {previewType === 'image' && <img src={previewUrl} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />}
            {previewType === 'video' && <video src={previewUrl} style={{ width: '100%', marginTop: '10px' }} autoPlay loop></video>}
            </>
          } {/* Show preview */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} disabled={loading}>Cancel</Button>
          <Button onClick={handleAddExercise} disabled={loading}>Add Exercise</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateChallenge;