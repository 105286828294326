import React, { useState } from 'react';
import {  Box, Typography, IconButton } from '@mui/material';
import { savingchallenger } from '../services/challengeService';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './firebaseConfig'; 
import GoogleIcon from '@mui/icons-material/Google'; // Import Google icon

interface AuthModalProps {
  onLoginSuccess: () => void;
  }

  const AuthModal: React.FC<AuthModalProps> = ({ onLoginSuccess }) => {
   
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const handleGoogleSignIn = async () => {
      if (isAuthenticating) return; // Prevent multiple requests
    
      setIsAuthenticating(true);
      
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        // You can access the user info here
        const user:any = result.user;
        
        if(user?.reloadUserInfo ){
          
          savingchallenger(user.reloadUserInfo).then(() => {
            console.log('Signup successful');
            
            onLoginSuccess();
          }).catch((error) => {
            console.error('Error logging in:', error);
          });
          console.log('User Info:', user);
          
        }else{
          console.error('Error during Google sign-in');
        }
        
      } catch (error) {
        console.error('Error during Google sign-in:', error);
      }
    };
  
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <IconButton onClick={handleGoogleSignIn} color="primary" style={{ width: 'auto', color: 'white'  }}>
        <GoogleIcon />
        <Typography variant="body1" style={{ marginLeft: 8 }}>Log in</Typography>
      </IconButton>
    </Box>
    );
  }


export default AuthModal;