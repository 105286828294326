import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMyChallenges } from '../services/challengeService';
import { 
  CircularProgress,
  Typography, 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  Chip,
  Box,
  useTheme,
  useMediaQuery,
  Grid2,
  ListItem,
  TextField,
  List,
  ListItemText,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button
} from '@mui/material';
import { styled } from '@mui/system';
import ChallengeCard from './ChallengeCart';

import {ChallengeInterface} from '../utils/ObjectInterface';
import { categoryList } from '../utils/siteHelpers';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));



const JoinedChallengesPage: React.FC = () => {
  const [challenges, setChallenges] = useState<ChallengeInterface[]>([]);
  const [pageType, setPageType] = useState('joined');
  const theme = useTheme();
  const navigate = useNavigate();
  const [showText, setShowText] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); 
  const [nextpage, setNextPage] = useState(null); 
  const [selectedStatus, setSelectedStatus] = useState<string | null>('');
  const [selectedCategory, setSelectedCategory] = useState(null); 
  const [selectedDifficulty, setSelectedDifficulty] = useState(null); 

  const handleStatusChange = (event: SelectChangeEvent<string | null>) => {
    event.preventDefault();
    if(event.target.value){
      //console.log(event.target.value)
      
      setSelectedStatus(event.target.value as string);
    }else{
      setSelectedStatus('');
    }

    setChallenges([]);
    setPage(1)
    setSelectedCategory(null);
    setSelectedDifficulty(null);
    

  };
  
  
   // Function to throttle the scroll event
  const throttle = (callback:any, limit:any) => {
    let lastCall = 0;
    return function (...args: any) {
      const now = Date.now();
      if (now - lastCall < limit) return; // Prevent the function call if it's too soon
      lastCall = now;
      return callback(...args);
    };
  };
  useEffect(() => {

    if (loading) return; // Ensure we don't fetch if already loading
    //console.log("Fetching challenges for page:", page);
    
    try {
      setLoading(true);
      getMyChallenges(page,selectedStatus,selectedCategory,selectedDifficulty).then((data) => {
        setChallenges((prevChallenges) => [...prevChallenges, ...data.data]);
        setNextPage(data.data.length < data.limit ? null : data.page + 1);
        setLoading(false);
      });  
      
    } catch (error) {
      //console.error('Failed to fetch challenges:', error);
    } finally {
      setLoading(false); // Reset loading state in finally block
    }
    /* const timer = setTimeout(() => {
      setShowText(true); // Show text after 10 seconds
    }, 10000);

    return () => clearTimeout(timer);  */
    
  }, [page,selectedStatus,selectedCategory,selectedDifficulty]);
 

  // Scroll event listener
  useEffect(() => {
    const handleScroll = throttle(() => {
      //console.log("1 > " , window.innerHeight + document.documentElement.scrollTop + 50 )
      //console.log("2 > " , document.documentElement.offsetHeight)
      if (
        window.innerHeight + document.documentElement.scrollTop + 50 >=
        document.documentElement.offsetHeight
      ) {
        if (!loading && nextpage) {
          //console.log("Fetching page:", nextpage);
          setPage(nextpage);
        }
      }
    }, 200); // Adjust the limit to your needs (200ms in this example)

    // Set up the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, nextpage]);


  const handleRemoveSearch = () => {
    setChallenges([]);
    setPage(1)
    setSelectedCategory(null);
    setSelectedDifficulty(null);
    //setIsEdited(true);
  };
  const handleAddSearchCategory = (category:any) => {
    //console.log(category)
    if(category && category !== selectedCategory){
      setChallenges([]);
      setPage(1)
      setSelectedCategory(category);
      setSelectedDifficulty(null);
    }
    
    //setIsEdited(true);
  };
  const handleAddSearchDifficulty = (difficulty:any) => {
    //console.log(category)
    if(difficulty && difficulty !== selectedDifficulty){
      setChallenges([]);
      setPage(1)
      setSelectedCategory(null);
      setSelectedDifficulty(difficulty);
    }
    
    //setIsEdited(true);
  };
  return (
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
    :
    <>
          {challenges.length === 0  && selectedStatus === '' ?
      <Typography variant="subtitle2" sx={{ textAlign: 'center', my: 10 }}>
          No challenges joined yet. Join a challenge to get started!
        </Typography> : (
        <Container maxWidth="lg" >
          <Select
            fullWidth
            displayEmpty
            value={selectedStatus}
            onChange={(e)=>handleStatusChange(e)}
          >
            <MenuItem value="">Search...</MenuItem>
            <MenuItem value="ongoing">Ongoing</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="invited">Invited</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
          </Select>
          {(selectedCategory || selectedDifficulty) && (
            <Box mt={2} position="relative" display="inline-flex" alignItems="center" mb={1}>
            <Chip 
              label={selectedCategory ? categoryList.find(e => e.value === selectedCategory)?.text : selectedDifficulty} 
              variant="outlined" 
              sx={{ position: 'relative' }} // Add some padding for the "x" button
            />
            <Button 
              variant="text" 
              color="error" 
              onClick={() => handleRemoveSearch()} 
              sx={{ 
                position: 'absolute', 
                top: '-5px',  // Adjust this as needed for positioning
                right: '-5px', 
                minWidth: '24px', 
                padding: 0,
                fontSize: '14px',
                lineHeight: 1,
                zIndex: 1 
              }}
            >
              <strong>x</strong>
            </Button>
          </Box>
          
          )}
        <Grid2 container spacing={1} mt={2}>
          
          {challenges.map((challenge,index) => (
            
              <ChallengeCard key={index}
              _id ={challenge._id}
              title =  {challenge.title}
              challengrstatus = {challenge.challenge_challenger ? challenge.challenge_challenger.status : ''}
              description = {challenge.description}
              duration_text = {challenge.duration_text}
              difficulty = {challenge.difficulty}
              visibility = {challenge.visibility}
              category = {challenge.category}
              pagetype={pageType}
              allow_edit={challenge.allow_edit}
              invite_link = {challenge.invite_link}
              joined={challenge.challenge_challenger ? true : false}
              completed = {challenge.completed}
              ongoing = {challenge.ongoing}
              searchcategory = {handleAddSearchCategory}
              searchdifficulty = {handleAddSearchDifficulty}
              />
           
          ))}
        </Grid2>
      </Container>
      )}
    </>
  );
};

export default JoinedChallengesPage;