import React from 'react';
import { Drawer } from '@mui/material';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

function Sidebar({ isOpen, onClose }: SidebarProps) {
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': { width: '240px' },
      }}
    >
      {/* Drawer content */}
    </Drawer>
  );
}

export default Sidebar;