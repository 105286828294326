import React, { useEffect, useRef, useState } from 'react';
import { Container,  CircularProgress, Grid2, Box, Chip, Button } from '@mui/material';
import { getChallenges } from '../services/challengeService';
import ChallengeCard from './ChallengeCart';
import {ChallengeInterface} from '../utils/ObjectInterface';
import { categoryList } from '../utils/siteHelpers';
import { relative } from 'path';
import { isConstructorDeclaration } from 'typescript';

const LandingPage: React.FC = () => {
  const [challenges, setChallenges] = useState<ChallengeInterface[]>([]);
  const [pageType, setPageType] = useState('feed');
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); 
  const [nextpage, setNextPage] = useState(null); 
  const [selectedCategory, setSelectedCategory] = useState(null); 
  const [selectedDifficulty, setSelectedDifficulty] = useState(null); 
  const [refreshing, setRefreshing] = useState(false);
  const [scrollTopCount, setScrollTopCount] = useState(0);
  const pullStart = useRef(0);
   // Function to throttle the scroll event
   const throttle = (callback:any, limit:any) => {
    let lastCall = 0;
    return function (...args: any) {
      const now = Date.now();
      if (now - lastCall < limit) return; // Prevent the function call if it's too soon
      lastCall = now;
      return callback(...args);
    };
  };

  useEffect(() => {
    if (loading) return; // Ensure we don't fetch if already loading
    //console.log("Fetching challenges for page:", page);
    try {
      setLoading(true);
      
      getChallenges(page,selectedCategory,selectedDifficulty).then((data) => {
        if(page === 1){
          setChallenges( [ ...data.data]);
        }else{
          setChallenges((prevChallenges) => [...prevChallenges, ...data.data]);
        }
        
        setNextPage(data.data.length < data.limit ? null : data.page + 1);
        setLoading(false);
      });  
      
    } catch (error) {
      console.error('Failed to fetch challenges:', error);
    } finally {
      setLoading(false); // Reset loading state in finally block
    }
    /* const timer = setTimeout(() => {
        //setShowText(true); // Show text after 10 seconds
      }, 10000);

    return () => clearTimeout(timer); // Cleanup timer on unmount */
    }, [page,selectedCategory,selectedDifficulty]);

  // Scroll event listener
  useEffect(() => {
    
    const handleScroll = throttle((e:any) => {
      
      const pullDistance = window.scrollY -  window.innerHeight;
      //console.log("pulldistance " , pullDistance)
      //console.log("  window.innerHeight === offsetheight ",  window.innerHeight )
      //console.log("  window.scrollY  == scrollTop ==  ",  window.scrollY  )
      //console.log("current length " , challenges.length)
      //console.log("c = " , (document.documentElement.offsetHeight * 2) * page)
      //932 * 3 = 1400 => 1 call
      // 2796 * 2796 => 2 call

      // 50 + ((932 * 2) * page) = 1 call
      //
      if (window.scrollY  >= (50 +( (document.documentElement.offsetHeight * 2) * page)))
       {
        //console.log("in")
        if (!loading && nextpage) {
          //console.log("Fetching page:", nextpage);
          setPage(nextpage);
        }
      } else if(!loading && (pullDistance < 0) && (challenges.length > 10)){
        //console.log("stage in")
        setPage(1);
        //setScrollTopCount(0);
      } 
    }, 200); // Adjust the limit to your needs (200ms in this example)

    // Set up the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, nextpage]);

  const handleRemoveSearch = () => {
    setChallenges([]);
    setPage(1)
    setSelectedCategory(null);
    setSelectedDifficulty(null);
    //setIsEdited(true);
  };
  const handleAddSearchCategory = (category:any) => {
    //console.log(category)
    if(category && category !== selectedCategory){
      setChallenges([]);
      setPage(1)
      setSelectedCategory(category);
      setSelectedDifficulty(null);
    }
    
    //setIsEdited(true);
  };
  const handleAddSearchDifficulty = (difficulty:any) => {
    //console.log(category)
    if(difficulty && difficulty !== selectedDifficulty){
      setChallenges([]);
      setPage(1)
      setSelectedCategory(null);
      setSelectedDifficulty(difficulty);
    }
    
    //setIsEdited(true);
  };

  return (
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
:
    <Container maxWidth="lg" >
      {(selectedCategory || selectedDifficulty) && (
            <Box mt={2} position="relative" display="inline-flex" alignItems="center" mb={1}>
            <Chip 
              label={selectedCategory ? categoryList.find(e => e.value === selectedCategory)?.text : selectedDifficulty} 
              variant="outlined" 
              sx={{ position: 'relative' }} // Add some padding for the "x" button
            />
            <Button 
              variant="text" 
              color="error" 
              onClick={() => handleRemoveSearch()} 
              sx={{ 
                position: 'absolute', 
                top: '-5px',  // Adjust this as needed for positioning
                right: '-5px', 
                minWidth: '24px', 
                padding: 0,
                fontSize: '14px',
                lineHeight: 1,
                zIndex: 1 
              }}
            >
              <strong>x</strong>
            </Button>
          </Box>
          
          )}
      <Grid2 container spacing={1}  >
        {challenges.map((challenge) => (
          
            <ChallengeCard  key={challenge._id}
            _id ={challenge._id}
            title =  {challenge.title}
            challengrstatus = {challenge.challenge_challenger ? challenge.challenge_challenger.status : ''}
            description = {challenge.description}
            duration_text = {challenge.duration_text}
            difficulty = {challenge.difficulty}
            visibility = {challenge.visibility}
            category = {challenge.category}
            promote_videolist = {challenge.promote_videolist}
            invite_link = {challenge.invite_link}
            completed = {challenge.completed}
            ongoing = {challenge.ongoing}
            pagetype={pageType}
            joined={challenge.challenge_challenger ? true : false}
            searchcategory = {handleAddSearchCategory}
            searchdifficulty = {handleAddSearchDifficulty}
            />
         
          
          
        ))} </Grid2>
    </Container>
  );
};

export default LandingPage;