import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, TextField, CircularProgress, Grid, Grid2, Card, CardHeader, CardContent } from '@mui/material';
import { processInvitation } from '../services/challengeService';
import ChallengeCard from './ChallengeCart';

const InvitedPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  //console.log(token)
  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const pageType = 'feed';
  const navigate = useNavigate();
  
  useEffect(() => {
    GetResult();
    const timer = setTimeout(() => {
      //setShowText(true); // Show text after 10 seconds
    }, 10000);

  return () => clearTimeout(timer); 
  }, []);

  const GetResult = async () => {
    try {
      //console.log(token)
      if(token){
        setLoading(true);
        const data = await processInvitation(token);
        //console.log(data)
        setResult(data);
        setLoading(false);
      }else{
        navigate('/');
      }
      
    } catch (error) {
      console.error('Error fetching challenger:', error);
    }
  };
  
  return (
    loading && !result ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
:
<Container maxWidth="lg" sx={{ mt: 2 }}>
  <Box>
  <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1, textAlign:"center" , mb: 3}} justifyContent="center" >
    Jump in! You've been invited to join this exciting challenge by <strong>{result.inviter}</strong>!
    </Typography>
  </Box>
<Grid2 container spacing={1} key={result.challenge._id}>
<ChallengeCard
      _id ={result.challenge._id}
      title =  {result.challenge.title}
      challengrstatus = {result.challenge.challenge_challenger ? result.challenge.challenge_challenger.status : ''}
      description = {result.challenge.description}
      duration_text = {result.challenge.duration_text}
      difficulty = {result.challenge.difficulty}
      visibility = {result.challenge.visibility}
      promote_videolist = {result.challenge.promote_videolist}
      invite_link = {result.challenge.invite_link}
      completed = {result.challenge.completed}
      ongoing = {result.challenge.ongoing}
      pagetype={pageType}
      />
</Grid2>
</Container>
  );
};

export default InvitedPage;