// src/AppRoutes.tsx
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ChallengeDetailsPage from './pages/ChallengeDetailsPage';
import Layout from './components/common/Layout';
import MyChallengesPage from './pages/MyChallengesPage';
import ProfilePage from './pages/ProfilePage';
import ChallengerProfilePage from './pages/ChallengerProfilePage';
import { getAuthToken } from './config/config';
import { useState } from 'react';
import CreateChallengePage from './pages/CreateChallenge';
import JoinedChallengesPage from './pages/JoinedChallengesPage';
import EditChallengePage from './pages/EditChallengePage';
import DeletePage from './pages/DeletePage';
import InvitedPage from './pages/InvitedPage';
import NotificationPage from './pages/NotificationPage';

const AppRoutes: React.FC = () => {
  const isLoggedIn = !!getAuthToken();
  const [isProfilePage, setIsProfilePage] = useState(true);
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <Layout isLoggedIn={isLoggedIn}>
            <LandingPage />
          </Layout>
        } />
        <Route path="/challenge/:id" element={
          <Layout isLoggedIn={isLoggedIn}>
            <ChallengeDetailsPage />
          </Layout>
        } />
        <Route path="/challenger/:id" element={
          <Layout isLoggedIn={isLoggedIn}  isProfilePage={isProfilePage}>
            <ChallengerProfilePage />
          </Layout>
        } />
        <Route path="/challenge/mine" element={
          <Layout isLoggedIn={isLoggedIn}>
            <JoinedChallengesPage />
          </Layout>
        } />
        <Route path="/profile" element={
          <Layout isLoggedIn={isLoggedIn} isProfilePage={isProfilePage}>
            <ProfilePage />
          </Layout>
        } />
        <Route path="/deleteaccount" element={
          <Layout isLoggedIn={isLoggedIn} isProfilePage={isProfilePage}>
            <DeletePage />
          </Layout>
        } />
        <Route path="/challenger/add" element={
          <Layout isLoggedIn={isLoggedIn}>
            <CreateChallengePage />
          </Layout>
        } />
        <Route path="/challenger/published" element={
          <Layout isLoggedIn={isLoggedIn} >
            <MyChallengesPage />
          </Layout>
        } />
        <Route path="/challenger/notification" element={
          <Layout isLoggedIn={isLoggedIn} isProfilePage={isProfilePage} >
            <NotificationPage />
          </Layout>
        } />
        <Route path="/challenge/edit" element={
          <Layout isLoggedIn={isLoggedIn} >
            <EditChallengePage />
          </Layout>
        } />
        <Route path="/invite" element={
          <Layout isLoggedIn={isLoggedIn}>
            <InvitedPage />
          </Layout>
        } />
         <Route
            path="/privacy-policy"
            element={
              <div style={{ width: '100%', height: '100vh' }}>
                <iframe 
                  src="privacypolicy.html" 
                  style={{ width: '100%', height: '100%', border: 'none' }} 
                  title="Privacy Policy"
                />
              </div>
            }
          />
         <Route
            path="/terms-and-conditions"
            element={
              <div style={{ width: '100%', height: '100vh' }}>
                <iframe 
                  src="termandcondition.html" 
                  style={{ width: '100%', height: '100%', border: 'none' }} 
                  title="Privacy Policy"
                />
              </div>
            }
          />
      </Routes>
      
    </Router>
  );
};

export default AppRoutes;