import React from 'react';
import { Box, Container, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Header from './Header';

interface LayoutProps {
  isLoggedIn: boolean;
  children: React.ReactNode;
  isProfilePage?: boolean;  
}

const Layout: React.FC<LayoutProps> = ({ isLoggedIn, children, isProfilePage }) => {
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header isProfilePage={isProfilePage || false}/>
      <Container component="main" sx={{ flexGrow: 1, py: 1 , mt:13 }}>
        {children}
      </Container>      
    </Box>
  );
};

export default Layout;