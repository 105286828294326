// src/pages/VideoCarousel.tsx
import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const VideoCarousel: React.FC<any> = ({ videoUrls }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState<number | null>(null); // Track the starting X position for touch
    const [currentvideourl, setcurrentvideourl] = useState<string | null>(null); // Track the starting X position for touch
    const [openVideoViewDialog, setOpenVideoViewDialog] = useState(false);
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : Math.max(videoUrls.length - 2, 0)));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < videoUrls.length - 2 ? prevIndex + 1 : 0));
    };

    const handleVideoViewDialogOpen = (currentvideourl:any) => {
        setcurrentvideourl(currentvideourl);
        setOpenVideoViewDialog(true);
      };
    
      const handleVideoViewDialogClose = () => {
        setOpenVideoViewDialog(false);
      };
    // Auto-play functionality
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 7000); // Change video every 7 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [currentIndex]);

 // Touch event handlers
 const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX); // Get the starting X position
};

const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startX !== null) {
        const currentX = e.touches[0].clientX; // Get the current X position
        const diffX = startX - currentX; // Calculate the difference

        // If the swipe is significant enough, navigate
        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                handleNext(); // Swipe left
            } else {
                handlePrev(); // Swipe right
            }
            setStartX(null); // Reset startX after handling the swipe
        }
    }
};

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', maxWidth: '100%', overflow: 'hidden' }}
            onTouchStart={handleTouchStart} // Add touch start event
            onTouchMove={handleTouchMove} // Add touch move event
        >
            <IconButton onClick={handlePrev} disabled={videoUrls.length <= 1} sx={{ position: 'absolute', left: 0 }}>
                <ArrowBackIos />
            </IconButton>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                {videoUrls.slice(currentIndex, currentIndex + 2).map((video:any, index:any) => (
                    <Box key={index} sx={{ width: '50%', height: 'auto', position: 'relative', overflow: 'hidden' }}>
                        
                        <video
                            muted={true}
                            src={video.url}
                            autoPlay={true}
                            loop={true}
                            style={{ 
                                width: '95%', // Make the video take full width of the container
                                height: '200px', // Set a fixed height for uniformity
                                objectFit: 'cover', // Cover the area without distortion
                                borderRadius: '10px', // Set rounded corners
                               // border: '1px solid white' // Set a white border (adjust color and width as needed)
                            }}
                            onClick={()=>handleVideoViewDialogOpen(video.url)} 
                        />
                        {video.challenger_id && 
                        
                        
                            <Typography 
                             variant="body2" 
                             sx={{ 
                                 position: 'absolute', 
                                 bottom: 0, 
                                 left: 0, 
                                 right: 0, 
                                 backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                                 color: 'white', // Text color
                                 padding: '5px', // Padding for the text
                                 textAlign: 'center' // Center the text
                             }}>
                               <a href={`/challenger/info?_id=${video.challenger_id}&p=feed`} style={{ textDecoration: 'none', color: 'inherit' }}>
                               {video.challenger_username}
                               </a> 
                                </Typography>
                            }
                          {/* Pop-up Dialog for Full-Screen Video */}
            {currentvideourl && <Dialog
                open={openVideoViewDialog} onClose={handleVideoViewDialogClose}
                maxWidth="lg" // Adjust the size of the dialog
                fullWidth
            >
                {/* Close Button */}
                <Button variant="contained" color="error" onClick={() => handleVideoViewDialogClose()} sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
                x
                </Button>
                {/* Full-Screen Video */}
                <video
                    src={currentvideourl}
                     // Show controls when in full-screen mode
                    autoPlay
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '10px',
                        objectFit: 'contain'
                    }}
                    />
                </Dialog>}
                    </Box>
                  
                ))}
            </Box>
            <IconButton onClick={handleNext} disabled={videoUrls.length <= 1} sx={{ position: 'absolute', right: 0 }}>
                <ArrowForwardIos />
            </IconButton>
            
        </Box>
    );
};

export default VideoCarousel;