import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Typography, Box, Tabs, Tab, Dialog, DialogContent, IconButton, Fab, Chip, DialogTitle, CircularProgress, Menu, MenuItem, Card, CardContent, Switch } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, PlayCircleOutline, Add } from '@mui/icons-material';
import {savingchallenger, deleteChallengeByChallenger, getChallengeDetails, removeChallenge, takeChallenge, updateChallengerChallenge,uploadHowToFiles } from '../services/challengeService';
import DailyProgressDisplay from './ProgressCalendar';
import { Snackbar, Alert } from '@mui/material';
import { getStatusColor,generateRandomString, categoryList, getDifficultyColor } from '../utils/siteHelpers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';    
import { Button, DialogActions } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import {MediaItemInterface,ProgressDataInterface,ChallengeDetailsInterface} from '../utils/ObjectInterface';
import {API_HOWTO_URL_PREFIX} from '../config/config';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {getAuthToken} from '../config/config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig'; 
import PeopleIcon from '@mui/icons-material/People';


interface AuthModalProps {
  onLoginSuccess: () => void;
  }


const MediaCarousel: React.FC<{ mediaItems: MediaItemInterface[] }> = ({ mediaItems }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previewMedia, setPreviewMedia] = useState<MediaItemInterface | null>(null);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mediaItems.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < mediaItems.length - 1 ? prevIndex + 1 : 0));
  };

  const handleMediaClick = (media: MediaItemInterface) => {
    setPreviewMedia(media);
  };

  const handleClosePreview = () => {
    setPreviewMedia(null);
  };

  const renderMediaItem = (item: MediaItemInterface) => (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative'
      }}
      onClick={() => handleMediaClick(item)}
    >
      {item.type === 'video' ? (
        <>
          <video src={item.url} autoPlay loop 
          style={{ width: '100%', height: '200px', objectFit: 'cover',borderRadius: '10px', 
              }} />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: '5px 10px',
              borderRadius: '5px',
              fontSize: '0.8rem',
            }}
          >
            <PlayCircleOutline />
          </Box>
        </>
      ) : (
        <img src={item.url} alt="Media" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      )}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, maxWidth: '100%', overflow: 'hidden' }}>
      <IconButton sx={{display:'none'}} onClick={handlePrev} disabled={mediaItems.length <= 2}>
        <ArrowBackIos  />
      </IconButton>
      <Box sx={{ display: 'flex', width: 320, justifyContent: 'center' }}>
        {renderMediaItem(mediaItems[currentIndex])}
        {mediaItems.length > 1 && renderMediaItem(mediaItems[(currentIndex + 1) % mediaItems.length])}
      </Box>
      <IconButton sx={{display:'none'}} onClick={handleNext} disabled={mediaItems.length <= 2}>
        <ArrowForwardIos />
      </IconButton>
      <Dialog open={!!previewMedia} onClose={handleClosePreview} maxWidth="lg">
        <DialogContent>
          {previewMedia && (
            previewMedia.type === 'image' ? (
              <img src={previewMedia.url} alt="Preview" style={{ width: '100%', height: 'auto' }} />
            ) : (
              <video src={previewMedia.url} autoPlay loop   style={{ width: '100%', height: 'auto' }} />
            )
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const ChallengeDetailsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('_id');
  const pagetype = searchParams.get('p');
  const [challengeDetails, setChallengeDetails] = useState<ChallengeDetailsInterface | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [subtabValue, setSubTabValue] = useState<number|null>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false); // State for delete confirmation dialog
  const [loading, setLoading] = useState(true); // Loading state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const wordLimit = 25;


  useEffect(() => {
    setIsLoggedIn(!!getAuthToken());
    
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      // This will run whenever isLoggedIn changes to true
      //console.log('User is logged in');
    }
  }, [isLoggedIn]);
  

  const handleGoogleSignIn = async () => {
    if(!isLoggedIn){
      const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // You can access the user info here
      const user:any = result.user;
      setIsLoggedIn(true);
      if(user?.reloadUserInfo ){
        
          
        await savingchallenger(user.reloadUserInfo);
        //console.log('User Info:', user);
        return true;
      }else{
        console.error('Error during Google sign-in');
        return false;
      }
      
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      return false;
    }
  }else{
    return true;
  }
    
};



  useEffect(() => {
    if (id) {
      getChallengeDetails(id)
        .then((data) => {
          setChallengeDetails(data);
          if(data?.challenge_challenger){
            setTabValue(1);
          }
        })
        .catch((err) => {
          setError("Failed to load challenge details. Please try again.");
          console.error(err);
        });
    }
    setLoading(false); 
  }, [id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if(newValue === 2){
      setSubTabValue(0);
    }else{
      setSubTabValue(null);
    }
  };
  const handleSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSubTabValue(newValue);
  };


  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDateChange = (date: Dayjs | null) => {   
    setStartDate(date);
  };

  const handleConfirm = async () => {
    handleDialogClose();
    //console.log(isLoggedIn)
      let r = false;
      if(!isLoggedIn){
        r = await handleGoogleSignIn();
        //console.log(r);
      }
      //console.log(r);
    if((r || isLoggedIn) && id && startDate){
      try {
        const challenge = await takeChallenge(id, startDate.toDate());
        if(challenge){
          setOpenSnackbar(true);
          const updatedData = await getChallengeDetails(id);
          setChallengeDetails(updatedData);
        }
      } catch (err) {
        setError("Failed to start the challenge. Please try again.");
        console.error(err);
      }
    }  
  };


  const handleUpdateProgress = async (date: string, filedata: any, description: string | null ) => {
    if (id) {
      try {
        setLoading(true);
        const randomFileName = generateRandomString(16) + '.' + filedata.name.split('.').pop();
        const uploadResult = await uploadHowToFiles(filedata,randomFileName); // Upload the file
        if (uploadResult) {
          const url = API_HOWTO_URL_PREFIX + randomFileName;
          const updatedProgressData = [...(challengeDetails?.challenge_challenger?.progress_data || [])];
          const existingIndex = updatedProgressData.findIndex(item => item.date === date);
          let obj_ : any = { date, url};
            if(description){
              obj_.description = description;
            }
          if (existingIndex !== -1) {
            updatedProgressData[existingIndex] =obj_;
          } else {
            updatedProgressData.push(obj_);
          }
          
          await updateChallengerChallenge( {_id: id,progress_data: updatedProgressData});
          // Refresh challenge details after updating progress
          const updatedData = await getChallengeDetails(id);
          setChallengeDetails(updatedData);
          setOpenSnackbar(true);
        }
        
      } catch (err) {
        setError("Failed to update challenge progress. Please try again.");
        console.error(err);
      }finally {
        setLoading(false); // Reset loading state
      }
    }
  };
  const handleToggle = async () => {
      setLoading(true);
      if(challengeDetails){
        const visi = challengeDetails?.challenge_challenger?.visibility;
        
        if(id){
          const response = await updateChallengerChallenge( {_id: id,visibility: visi === 'public' ?'private': 'public'});
          // Refresh challenge details after updating progress
          const updatedData = await getChallengeDetails(id);
          setChallengeDetails(updatedData);
          setLoading(false);
        }
      }
  };
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const handleDeleteChallenge = () => {
    setDeleteDialogOpen(true); // Open the delete confirmation dialog
};

const confirmDeleteChallenge = async () => {
    try {
        if(id){
          await deleteChallengeByChallenger(id); // Call the delete function
          setOpenSnackbar(true);
          navigate('/challenger/published'); // Navigate to My Challenges page after deletion
        }
        
    } catch (err) {
        console.error(err);
        setError('Failed to delete challenge. Please try again.');
    } finally {
        setDeleteDialogOpen(false); // Close the dialog
    }
};


const handleRemoveChallenge = () => {
  setRemoveDialogOpen(true); // Open the delete confirmation dialog
};

const confirmRemoveChallenge = async () => {
  try {
      if(id){
        await removeChallenge(id); // Call the delete function
        setOpenSnackbar(true);
        navigate('/challenge/mine'); // Navigate to My Challenges page after deletion
      }
      
  } catch (err) {
      console.error(err);
      setError('Failed to delete challenge. Please try again.');
  } finally {
      setRemoveDialogOpen(false); // Close the dialog
  }
};

//
const handleClick = (event:any) => {
  setAnchorEl2(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl2(null);
};

const handleEdit = (e:any,_id:string) => {
  e.stopPropagation();
  navigate(`/challenge/edit?_id=${_id}&p=${pagetype}`);
  handleClose();
};

const handleDelete = (e:any) => {
  e.stopPropagation();
  // Add delete logic here
  handleDeleteChallenge();
  //console.log('Delete clicked');
  handleClose();
};

const handleRemove = (e:any) => {
  e.stopPropagation();
  // Add share logic here
  //console.log('Remove clicked');
  handleRemoveChallenge();
  handleClose();
};



  return (
    loading ?     <CircularProgress sx={{ display: loading ? 'block' : 'none', mx: 'auto' }} />
:
    <Container maxWidth="lg" sx={{ mt: 2, position: 'relative', minHeight: '100vh' }}>
      {challengeDetails && (
        <>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1 }}>
              {challengeDetails.title}
               {(challengeDetails.allow_edit || challengeDetails.challenge_challenger) && ( // Conditionally render the Edit icon
              <>
              
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {(challengeDetails.allow_edit) && 
                  (<>
                  <MenuItem onClick={(event)=>handleEdit(event, challengeDetails._id)}>
                    <EditIcon sx={{ cursor: 'pointer', color: 'primary.main', marginRight: '8px' }} />
                    Edit Challenge
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>
                    <DeleteIcon sx={{ cursor: 'pointer', color: 'error.main', marginRight: '8px' }} />
                    Delete Challenge
                  </MenuItem>
                  </>)
                }
                {(challengeDetails.challenge_challenger) && 
                  <MenuItem onClick={handleRemove}>
                    <RemoveCircleIcon sx={{ cursor: 'pointer', color: 'primary.main', marginRight: '8px' }} />
                    Remove Challenge
                  </MenuItem>
                }
                
              </Menu>
              
              </>
          )}
            </Typography>
            <Chip
              label={challengeDetails.difficulty}
              color={getDifficultyColor(challengeDetails.difficulty)}
              size="small"
              sx={{
                width: 55, // Fixed width in pixels (adjust as needed)
                display: 'flex',
                justifyContent: 'center', // Center the text inside the Chip
                whiteSpace: 'nowrap', // Prevent text from wrapping
                overflow: 'hidden', // Hide any overflowing text
                textOverflow: 'ellipsis', // Add ellipsis if text overflows
                color: 'black'
              }}
              />
            
          </Box>
          {/* {challengeDetails.challenge_challenger && challengeDetails.challenge_challenger.status &&
              <Typography>
                {challengeDetails.challenge_challenger ? challengeDetails.challenge_challenger.status : ''}
              </Typography>
            }
             */}
          <Typography variant="body2" gutterBottom>
           {showFull || (challengeDetails.description.split(' ').length < wordLimit) ? challengeDetails.description : `${challengeDetails.description.split(' ').slice(0, wordLimit).join(' ')}...`}
            {challengeDetails.description.split(' ').length > wordLimit && (
            <Button sx={{fontSize: "1",textTransform: 'none'}} size="small" onClick={() => setShowFull(!showFull)}>
              {showFull ? 'See less' : 'See more'}
            </Button>
          )}
          </Typography>

          
          <Typography variant="body2" gutterBottom>
          🗓️ {challengeDetails.duration_text}
          </Typography>
          {/* {challengeDetails.creator_username && 
          <Typography variant="body2" gutterBottom>
          👤 {challengeDetails.creator_username}
          </Typography>
          } */}
          {challengeDetails.challenge_challenger && 
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            Private: <Switch checked={challengeDetails?.challenge_challenger?.visibility === 'private' ? true : false} onChange={handleToggle} /> 
            </Box>
          }
          {challengeDetails.category && (
            <Box marginTop={3}>
            <Chip label={categoryList.filter((e)=>e.value === challengeDetails.category)[0].text} variant="outlined"  />
            </Box>
          )}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="challenge details tabs" sx={{ width: '100%' }}>
              <Tab label="How To" sx={{ width: '35%' }} />
              <Tab label="Progress" sx={{ width: '32%' }} />
              <Tab 
                icon={
                  
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PeopleIcon sx={{ verticalAlign: 'bottom', mr: 1 }} /> 
                    <Typography variant="body1" component="span">
                      {(challengeDetails?.ongoing?.count || 0) + (challengeDetails?.completed?.count || 0)}
                    </Typography>
                  </Box>
                }
                sx={{ width: '32%' }} 
                component="div" // Specify the component type
                onClick={(event: React.MouseEvent<HTMLDivElement>) => { // Ensure the event type is correct
                  handleSubTabChange(event,0);
                  setAnchorEl(event.currentTarget);
                }}
              />
              
              </Tabs>
              <Menu
                id="participants-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)} 
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => {setSubTabValue(0);setAnchorEl(null);} } selected={subtabValue === 0}>Ongoing ({challengeDetails?.ongoing?.count || 0})</MenuItem>
                <MenuItem onClick={() => {setSubTabValue(1);setAnchorEl(null); }} selected={subtabValue === 1}>Completed ({challengeDetails?.completed?.count || 0})</MenuItem>
              </Menu>
          </Box>

          <Box role="tabpanel" hidden={tabValue !== 0}>
            {tabValue === 0 && challengeDetails.challenge_object && (
              <Box sx={{ mt: 2 }}>
                {challengeDetails.challenge_object.sort((a, b) => a.no - b.no).map((obj) => (
                  <Box key={`step-${obj.no}`} sx={{ mb: 2 }}>
                    <Typography variant="body2" gutterBottom>Step {obj.no}: {obj.description}</Typography>
                    {obj.object_data && obj.object_data.length > 0 && (
                      <MediaCarousel mediaItems={obj.object_data} />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box role="tabpanel" hidden={tabValue !== 1}>
            {tabValue === 1 && challengeDetails.challenge_challenger && (
              <Box sx={{ mt: 2 }}>
                <DailyProgressDisplay 
                  duration_value={challengeDetails.duration_value} 
                  duration_unit={challengeDetails.duration_unit} 
                  startDate={new Date(challengeDetails.challenge_challenger.start_date)} 
                  endDate={new Date(challengeDetails.challenge_challenger.end_date)} 
                  currentDate={new Date()} 
                  progressData={challengeDetails.challenge_challenger.progress_data}
                  progress={challengeDetails.challenge_challenger.progress}
                  onUpdateProgress={handleUpdateProgress}
                />
              </Box>
            )}
          </Box>

          <Box role="tabpanel" hidden={subtabValue !== 0}>
            {subtabValue === 0 && challengeDetails?.ongoing?.challenger_list && (
              <Box sx={{ mt: 2 }}>
                {challengeDetails.ongoing.challenger_list.map((obj) => (
                  <Card key={obj.challenger_id} sx={{ mb: 2 }} onClick={() => navigate(`/challenger/info?_id=${obj.challenger_id}&p=${pagetype}`)}>
                    <CardContent>
                      <Typography variant="body2" gutterBottom>Challenger: {obj.challenger_username}</Typography>
                      <Typography variant="body2" gutterBottom>Progress: {obj.progress} %</Typography>
                      <Typography variant="body2" gutterBottom>Status: Ongoing</Typography>
                      <Typography variant="body2" gutterBottom>Start Date: {new Date(obj.start_date).toLocaleDateString()}</Typography>
                      <Typography variant="body2" gutterBottom>End Date: {new Date(obj.end_date).toLocaleDateString()}</Typography>
                    </CardContent>
                  </Card>
                ))}
                {challengeDetails.ongoing.count > challengeDetails.ongoing.challenger_list.length && 
                <Typography variant="body2" gutterBottom>Some challengers love to participate private.</Typography>
                }
              </Box>
            )}
          </Box>
          
          <Box role="tabpanel" hidden={subtabValue !== 1}>
            {subtabValue === 1 && challengeDetails?.completed?.challenger_list && (
              <Box sx={{ mt: 2 }}>
                {challengeDetails.completed.challenger_list.map((obj) => (
                  <Card key={obj.challenger_id} sx={{ mb: 2 }} onClick={() => navigate(`/challenger/info?_id=${obj.challenger_id}&p=${pagetype}`)}>
                    <CardContent>
                      <Typography variant="body2" gutterBottom>Challenger: {obj.challenger_username}</Typography>
                      <Typography variant="body2" gutterBottom>Status: Completed</Typography>
                      <Typography variant="body2" gutterBottom>Start Date: {obj.start_date}</Typography>
                      <Typography variant="body2" gutterBottom>End Date: {obj.end_date}</Typography>
                    </CardContent>
                  </Card>
                ))}
                {challengeDetails.completed.count > challengeDetails.completed.challenger_list.length && 
                <Typography variant="body2" gutterBottom>Some challengers love to participate private.</Typography>
                }
              </Box>
            )}
          </Box>
          {(challengeDetails.challenge_challenger === null || challengeDetails.challenge_challenger.status === 'removed'  || challengeDetails.challenge_challenger.status === 'failed') && (
            <Fab
              color="primary"
              aria-label="start challenge"
              sx={{
                background: '#90caf9',
                color:'black',
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 80,
                height: 80,
                fontSize: '1.2rem',
                animation: 'pulse 1.5s infinite',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(0, 123, 255, 0.7)',
                  },
                  '70%': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 0 0 10px rgba(0, 123, 255, 0)',
                  },
                  '100%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(0, 123, 255, 0)',
                  },
                },
              }}
              onClick={handleDialogOpen}
            >
              Join!
            </Fab>
          )}
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          You're in! Challenge accepted!
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="start-challenge-dialog-title">
        <DialogTitle id="start-challenge-dialog-title">Ready to start your challenge?</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleDateChange}
              minDate={dayjs()}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Let's Go!
          </Button>
        </DialogActions>
      </Dialog>
       {/* Delete Confirmation Dialog */}
       <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this challenge?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteChallenge} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
       {/* Remove Confirmation Dialog */}
       <Dialog open={removeDialogOpen} onClose={() => setRemoveDialogOpen(false)}>
                <DialogTitle>Confirm Remove</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to withdraw from this challenge?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRemoveDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmRemoveChallenge} color="secondary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

    </Container>
  );
}

export default ChallengeDetailsPage;