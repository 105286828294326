import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Tabs, Tab, Avatar, Menu, MenuItem, Drawer, List, ListItem, ListItemText, ListItemButton, IconButton, Alert, Badge } from '@mui/material';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {getChallengerAgain, getAuthToken, getChallenger, removeAuthToken, removeChallenger,BrandText,MountainText } from '../../config/config';
import GetAppIcon from '@mui/icons-material/GetApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { deleteAccount} from '../../services/challengeService';

import Sidebar from './Siderbar'; // Assuming you have a separate Sidebar component
import AuthModal from '../../auth/AuthModal';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

interface Challenger {
  username?: string;
  email?: string;
  photo?: string;
}

interface HeaderProps {
  isProfilePage: boolean;
}

// Define the BeforeInstallPromptEvent interface
interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const Header: React.FC<HeaderProps> = ({ isProfilePage }) => {
  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [challenger, setChallenger] = useState<Challenger | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loginokay, setLoginOkay] = useState(false);
  const [deleteokay, setDeleteOkay] = useState(false);
  const [deletenotokay, setDeleteNotOkay] = useState(false);
  const [searchParams] = useSearchParams();
  const previouspage = searchParams.get('p');

  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [noticount, setNotiCount] = useState(null);

  useEffect(() => {
    // Cast the event to the custom BeforeInstallPromptEvent
    const handleBeforeInstallPrompt = (event: Event) => {
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      // Prevent the mini-infobar from appearing on mobile
      beforeInstallPromptEvent.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(beforeInstallPromptEvent);
      // Set flag to display the install button
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = (): void => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the install prompt');
        } else {
          //console.log('User dismissed the install prompt');
        }
        // Clear the deferredPrompt as it's only usable once
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  useEffect(() => {
    setIsLoggedIn(!!getAuthToken());
    
  }, []);

  useEffect(() => {

    fetchChallenger();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        // Fetch or update the latest data here
        console.log("App is now visible. Fetching latest data...");
        const challenger_  = await getChallengerAgain();
        //console.log(challenger)
        if (challenger_ && challenger_.username) {
          setChallenger(challenger_);
          //handleClose();
          setNotiCount(challenger_.noti_count);
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/' || previouspage === 'feed') setValue(0);
    else if (location.pathname === '/challenge/published' ||  previouspage === 'creator') setValue(1);
    else if (location.pathname === '/challenge/mine'  || previouspage === 'joined') setValue(2);
  }, [location]);


  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    
    fetchChallenger();
    window.location.reload();
  };
  const handleSignupSuccess = () => {
    setIsLoggedIn(true);
    fetchChallenger();
  };
  const fetchChallenger = async () => {
    try {
      const challenger_  = await getChallenger();
      //console.log(challenger)
      if (challenger_ && challenger_.username) {
        setChallenger(challenger_);
        handleClose();
       
      }
    } catch (error) {
      console.error('Error fetching challenger:', error);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //console.log('newValue', newValue);
    setValue(newValue);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeAuthToken();
    removeChallenger();
    setIsLoggedIn(false);
    navigate('/');
    window.location.reload();
  };
  const handleDelete = async () => {
    const success_ = await deleteAccount();
    if(success_){
      setDeleteOkay(true);
      removeAuthToken();
      removeChallenger();
      setIsLoggedIn(false);
      navigate('/');
      window.location.reload();
    }else{
      setDeleteNotOkay(true);
    }
    
  };

  const handleFeedClick = () => {
    if (location.pathname === '/') {
      // If already on Feed, reload the page
      navigate(0);

    } else {
      // Navigate to Feed
      navigate('/');
    }
  };

  return (
    <>
      <AppBar   sx={{position:"fixed", top:0, left:0, right: 0,  zIndex: 1100}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Button color="inherit" onClick={handleFeedClick}>
              {MountainText + " " + BrandText}
            </Button>
          </Typography>
          {isInstallable && (
            <Button 
              variant="text" 
              onClick={handleInstallClick}
            >
              <GetAppIcon />
            </Button>
          )}
          {isLoggedIn && challenger ? (
            <div style={{display: 'flex',alignItems: 'center' }}>
              
              <IconButton onClick={() => navigate('/challenger/add?p=creator')} sx={{ cursor: 'pointer' }} >
                
                <LibraryAddIcon fontSize="medium" sx={{color:  "white"}}/>
              </IconButton>
              <IconButton onClick={() =>{
                setNotiCount(null);
                navigate('/challenger/notification')
              }} sx={{ cursor: 'pointer' }} >
              <Badge badgeContent={noticount} color="primary">
                <NotificationsIcon fontSize="medium" sx={{color: location.pathname === '/challenger/notification' ? 'primary.main' : "white"}}/> 
              </Badge>&nbsp; 
              </IconButton>
              <Avatar 
              src={challenger?.photo}
              onClick={handleMenu} sx={{ cursor: 'pointer' }}>
                { challenger?.username?.charAt(0)  || 'U'}
              </Avatar>
              
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate('/profile')}>{challenger?.username || 'Profile'}</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : !loginokay && !challenger && (
            <span onClick={()=>setLoginOkay(true)}>
            
              <AuthModal 
                onLoginSuccess={handleLoginSuccess}
              />
            </span>
          )}
        </Toolbar>
      </AppBar>
      {!isProfilePage && 
        <Tabs  
        sx={{ position: "fixed", top: 55.5, left: 0, right: 0, background: "black", zIndex: 1100 }}
        value={value} onChange={handleChange} centered>
        <Tab 
          label="Feed" 
          onClick={handleFeedClick}
        />
        <Tab 
          label="My Challenges" 
          onClick={() => navigate('/challenger/published')}
        />
        <Tab 
          label="Joined" 
          onClick={() => navigate('/challenge/mine')}
        />
      </Tabs>
      }
      {loginokay && <Alert severity="success">You're almost there!</Alert>}
      {deleteokay && <Alert severity="success">Your account is successfully deleted. See you again!</Alert>}
      {deletenotokay && <Alert severity="error">Your account is failed to delete. Try again!</Alert>}
      <Sidebar isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
};

export default Header;
