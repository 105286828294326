// src/App.tsx
import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

/* import {
  requestNotificationPermission,
  handleIncomingMessages,
} from "./firebase/notification"; */
import AppRoutes from "./AppRoutes";
import './App.css';

const theme = createTheme({
  typography: {
   
    body2: {
      fontSize: 14,
    },
    body3: {
      fontSize: 11,
    },
    body4: {
      fontSize: 9,
    },
  },
  palette: {
      mode: 'dark', // Set the theme mode to dark
      background: {
          default: '#000', // Set default background color
          paper: '#121212', // Set paper background color 
      },
      text: {
          primary: '#fff', // Set primary text color
          secondary: '#fff', // Set secondary text color
      },
  },
  components: {
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: '16px', // Set the desired border radius
                background:'#282c34'
            },
        },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
            paddingLeft: '5px', // Set the desired border radius
            paddingRight: '10px', // Set the desired border radius
        },
      },
    },
    MuiChip:{
      
      styleOverrides:{
        root:{
          fontSize:10
        }
      }
    },
    MuiButton: {
      
      styleOverrides:{
        root:{
          fontSize:13
        }
      }
    },
    MuiTab:{
      styleOverrides:{
        root:{
          fontSize:13
        }
      }
    },
    MuiAppBar:{
      styleOverrides:{
        root:{
          background:'#282c34'
        }
      }
    },
    MuiPaper:{
      styleOverrides:{
        root:{
          background:'#282c34'
        }
      }
    }
},
});

// Step 2: Extend Material-UI's theme typing to include 'body3'
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // Allow 'body3' in `Typography`'s `variant` prop
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}


const App: React.FC = () => {
  
  return (
    <ThemeProvider theme={theme}>
            <div className="App mobile-wrapper">
      <AppRoutes />
    </div>
    </ThemeProvider>
    
  );
};

export default App;
