import React, { useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Typography, Box, Button,  TextField, Grid2, Avatar, IconButton, Paper, Card, CardContent, CardHeader, Chip, ListItemIcon, ListItemText, List, Collapse, ListItemButton, Tabs, Tab } from '@mui/material';
import { API_HOWTO_URL_PREFIX, API_USER_URL_PREFIX, getChallenger } from '../config/config';
import { updateChallenger,get_challenger_statistics,uploadUserFiles } from '../services/challengeService';
import EditIcon from '@mui/icons-material/Edit';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { getStatusColor,generateRandomString, getMediaType } from '../utils/siteHelpers';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Settings from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import VideoCarousel from './VideoCarousel';

interface Challenger {
  username?: string;
  email?: string;
  password?: string;
  photo?: string;
  bio?: string;
}

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const [challenger, setChallenger] = useState<any>(null);
  const [challengerStatistics, setChallengerStatistics] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedChallenger, setEditedChallenger] = useState<Challenger | null>(null);
  const [file, setFile] = useState<File | null>(null); 
  const [error, setError] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); 
  const [videoUrl, setVideoURL] = useState<Array<any>>([]);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    fetchChallenger();
    fetchChallengerStatistics();
  }, []);

  const fetchChallenger = async () => {
    try {
      const data = await getChallenger();
      setChallenger(data);
      setEditedChallenger(data);
    } catch (error) {
      console.error('Error fetching challenger:', error);
    }
  };
  const fetchChallengerStatistics = async () => {
    try {
      const data = await get_challenger_statistics();
      setChallengerStatistics(data);
      setVideoURL([]);
      data?.challenges?.map((each:any)=>{
        if(each.challenge_challenger && each.challenge_challenger.progress_data.length > 0){
          const newVideoUrls: string[] = []; // Temporary array to hold new video URLs
          const current = each.challenge_challenger.progress_data;
            if (current.length > 0) {
              const url = current[current.length - 1];
              //console.log(url);
              if (url && !newVideoUrls.includes(url)) { // Check for duplicates
                newVideoUrls.push(url); // Add to temporary array
              }
            }
          setVideoURL((prev) => [...prev, ...newVideoUrls]);
          //console.log(newVideoUrls);
        }
      });
    } catch (error) {
      console.error('Error fetching challenger:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      if(editedChallenger){
        let photo = null;
        if(file){
          const randomFileName = generateRandomString(16) + '.' + file.name.split('.').pop();
          const uploadResult = await uploadUserFiles(file,randomFileName); // Upload the file
          if (uploadResult) {
            photo = API_USER_URL_PREFIX + randomFileName;
          }
        }
        if(photo ){
          editedChallenger.photo = photo;
        }
        const updated = await updateChallenger(editedChallenger);
        if(updated){
          fetchChallenger();
        }
      }
      
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating challenger:', error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedChallenger(challenger);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsEditing(true);
      const selectedFile = e.target.files[0];
      const uploadtype = await getMediaType(selectedFile?.name);
      if (uploadtype === 'image') {
        setFile(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile));
          
      } else {
        setError('Image only.');
        setFile(null); // Clear the file input
        setPreviewUrl(null); // Clear the preview URL
      }
    
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if ((name === "bio" && value.length <= 50) || (name === "username" && value.length <= 8)) {
      // Update the state only if the character count is within the limit
      setEditedChallenger(prevChallenger => ({ ...prevChallenger, [name]: value }));
    }
    
    //setEditedChallenger();
  };
  if (!challenger) return <Typography>Loading...</Typography>;

  return (
    <>
    <Tabs  
        sx={{ position: "fixed", top: 55.5, left: 0, right: 0, background: "black", zIndex: 1100 }}
        value={0} centered>
          <Tab 
            label={<span ><span style={{color:'White'}}>Consistency Rank &nbsp;</span>
            [&nbsp;{videoUrl && videoUrl.length >= 10 ? 'Grandmaster' :
            videoUrl && videoUrl.length >= 7 ? 'Master' :
            videoUrl && videoUrl.length >= 4 ? 'Expert' :
            videoUrl && videoUrl.length >= 1 ? 'Novice' :
            'Beginner'}
                            &nbsp;]</span> } 
          />
        </Tabs>
    
      <Container maxWidth="lg" sx={{ mt:2, position: 'relative', minHeight: '100vh'}}>
        
        <Grid2 container  spacing={1} >
          
        <Grid2  container size={4} >
        <IconButton
            component="label" // Use component="label" to wrap the input in the IconButton
            sx={{
              color: '#c9c1c1',
              position: 'absolute',
              top: 0,
              left: 100,
              transform: 'translateX(-50%)',
              zIndex: 1,
            }}
          >
            <AddAPhotoIcon fontSize="small" />
            <input
              accept="image/*"
              type="file"
              style={{ display: 'none' }} // Hide input but make it clickable through the icon
              onChange={handleImageChange}
            />
          </IconButton>
          <Box
            sx={{
              width: 104, // Avatar size (100) + border thickness (2) * 2
              height: 104,
              borderRadius: '50%',
              border: '2px solid', // Thickness of the border
              borderColor: 'primary.main', // You can change this to any color
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
              cursor: 'pointer',
            }}
          >
            <Avatar
              alt="Profile Picture"
              src={previewUrl || challenger?.photo}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
        </Grid2>
        <Grid2 container size={8}  direction="column">
        <Typography variant="subtitle1" component="div" sx={{ display: 'flex' }}>
              <strong>
                {challenger.username}
              <EditIcon
                fontSize='small'
              onClick={handleEdit}
              sx={{ cursor: 'pointer', color: 'primary.main' }}
            /></strong>
            </Typography>
            <Typography variant="body2" component="div" sx={{ display: 'flex' }}>
              {challenger.bio}
            </Typography>
            <Typography variant="body3">
              Completed: {challengerStatistics?.completed_challenges || 0} . Ongoing: {challengerStatistics?.ongoing_challenges  || 0}
            </Typography>
        </Grid2>
            
        </Grid2>
        {challenger ? (
        <Grid2 container spacing={3}>
          <Grid2 >
          <Box>
              {isEditing && (
                <>
                  <TextField
                    name="username"
                    label="Username"
                    value={editedChallenger?.username || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    onKeyDown={(e) => {
                      if ((editedChallenger?.username || '').length > 8 && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault(); // Prevent additional typing if limit is reached
                      }
                    }}
                    helperText="Maximum 8 characters allowed"
                  />
                  <TextField
                    name="bio"
                    label="Bio"
                    value={editedChallenger?.bio || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    
                    helperText="Maximum 50 characters allowed"
                  />
                  <Button onClick={handleSave}>Save</Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </>
              ) }
            </Box>
            
          </Grid2>
        </Grid2>
      ) : (
        <Typography variant="body2">
          Please log in to view your profile.
        </Typography>
      )}
        <Card sx={{mt:2}}>
          <CardContent>
            <Typography variant="body2" component="div" sx={{ flexGrow: 1, mr: 1 }} >
              Email: {challenger?.email}
            </Typography> 
          </CardContent>
          </Card>
        <Card  sx={{mt:2}}>
              <CardContent>
              <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, mr: 1, mb:2 }} >
              Highlighted videos
                            </Typography>
                            { videoUrl.length > 0 && <VideoCarousel videoUrls={videoUrl} /> }

              </CardContent>
            </Card>
            < Card   sx={{mt:2}}>
            <List>
              <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Advanced" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton  sx={{ pl: 4 }}
                      onClick={() => navigate('/deleteaccount')}
                      >
                        <ListItemIcon>
                          <DeleteIcon color={'error'} />
                        </ListItemIcon>
                        <ListItemText sx={{color:'red'}} primary="Delete My Account" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
            </Card>
    </Container>
    </>
  );
}

export default ProfilePage;