import React, { useState } from 'react';
import { Box, Typography, LinearProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert } from '@mui/material';
import {checkVideoDuration, getMediaType } from '../utils/siteHelpers';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import {DailyProgressDisplayPropsInterface} from '../utils/ObjectInterface';

const DailyProgressDisplay: React.FC<DailyProgressDisplayPropsInterface> = ({
    duration_value, duration_unit, startDate, endDate, currentDate, progressData, progress, onUpdateProgress
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [currentProgress, setCurrentProgress] = useState(progress);
  const [error, setError] = useState<string | null>(null);
  const [error1, setError1] = useState<string | null>(null);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [selectedVideoDescription, setSelectedVideoDescription] = useState<string | null>(null);
  const [selectedVideoDate, setSelectedVideoDate] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null); // File state for upload
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // Preview URL for the uploaded file
  const [previewType, setPreviewType] = useState<string | null>(null); // Preview URL for the uploaded file
  const [Description, setDescription] = useState<string | null>(null); // Preview URL for the uploaded file
  const [isEdited, setIsEdited] = useState<boolean | null>(false); // Preview URL for the uploaded file


  let totalDays = 0;
  switch (duration_unit) {
    case 'day':
      totalDays = duration_value;
      break;
    case 'week':
      totalDays = duration_value * 7;
      break;
    case 'month':
      totalDays = duration_value * 30;
      break;
    case 'year':
      totalDays = duration_value * 365;
      break;
  }

  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setVideoUrl('');
  };

  const handleSubmitVideo = async () => {
    if (selectedDate && file) {
      onUpdateProgress(selectedDate, file, Description);
        handleCloseVideoDialog();
    }
  };
  const handleUpdateVideo = async () => {
    if (selectedVideoDate && file && isEdited) {
      onUpdateProgress(selectedVideoDate, file, selectedVideoDescription);
        handleCloseDialog();
      
    }
  };
  
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setError1(null);
      const selectedFile = e.target.files[0];

      const uploadtype = await getMediaType(selectedFile?.name);
      if (uploadtype === 'video') {
        try {
            const isValidDuration = await checkVideoDuration(selectedFile);
            if (!isValidDuration) {
                setError1('Video duration must be 30 seconds or less.');
                setFile(null); // Clear the file input
                setPreviewUrl(null); // Clear the preview URL
                setPreviewType(null); // Clear the preview type
            } else {
              setError1(null);
                setFile(selectedFile);
                setPreviewUrl(URL.createObjectURL(selectedFile));
                setPreviewType(uploadtype); // Create a preview URL for the selected file
            }
        } catch (error) {
            setError1('Error loading video metadata.');
            console.error(error);
        }
    } else {
        setError1('Only video files are allowed.');
        setFile(null); // Clear the file input
        setPreviewUrl(null); // Clear the preview URL
        setPreviewType(null); // Clear the preview type
    }
      
    }
  };
  const handleOpenDialog = (date: string) => {
    setSelectedDate(date);
    setOpenDialog(true);
  };

  const handleOpenVideoDialog = async (url: string,date:string,description?:string) => {
    //console.log(url)
    setSelectedVideoUrl(url);
    setSelectedVideoDate(date);
    setSelectedVideoDescription(description || null);
    setOpenVideoDialog(true);
  };

  const handleCloseVideoDialog = () => {
    setOpenVideoDialog(false);
    setSelectedVideoDate(null);
    setSelectedVideoUrl(null);
  };
  
  const handleRemoveVideo = () => {
    setSelectedVideoUrl(null);
    setIsEdited(true);
  };

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 2 }}>
         To complete your progress, simply upload a quick 30-second video each day. Click the  <Button variant="contained"  style={{ backgroundColor: '#1976d2' }}> <VideoCallIcon /> </Button> to upload and continue moving forward!
      </Typography>
      {Array.from({ length: totalDays }, (_, index) => {
        const date = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
        const dateString = date.toISOString().split('T')[0];
        const dayProgress = progressData.find(item => item.date === dateString);
        const isPast = date < currentDate;
        const isToday = date.toDateString() === currentDate.toDateString();

        let progressValue = 0;
        let progressColor = 'error.main'; // Default to "Not Done"

        if (dayProgress && dayProgress.url) {
          progressValue = 100; // Completed
          progressColor = 'success.main'; // Completed color
        }


        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1, border: '1px solid #ccc', borderRadius: '8px', p: 2 }}>
            <Typography variant="body2" sx={{ minWidth: 100 }}>
              {date.toLocaleDateString()}
            </Typography>
            <Box sx={{ flexGrow: 1, mx: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progressValue}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: progressValue === 0 ? 'rgba(255, 0, 0, 0.3)' : undefined,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: progressColor,
                  }
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {( isToday) && (!dayProgress || !dayProgress.url) && (
                
                <Button variant="contained" onClick={() => handleOpenDialog(dateString)} > <VideoCallIcon /> </Button>
              )}
              {( isPast && !isToday) && (!dayProgress || !dayProgress.url) && (
                
                <Button variant="contained" onClick={() => handleOpenDialog(dateString)}  style={{ backgroundColor: 'grey' }}> <VideoCallIcon /> </Button>
              )}
              {dayProgress && (
                <Button variant="contained" onClick={() => handleOpenVideoDialog(dayProgress.url, dayProgress.date, dayProgress.description)} style={{ backgroundColor: 'red' }}><VideoCallIcon /></Button>
              )}
            </Box>
          </Box>
        );
      })}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Upload Proof</DialogTitle>
  <DialogContent>
    <TextField
      label="Describe your experience..."
      fullWidth
      margin="normal"
      value={Description}
      onChange={(e) => setDescription(e.target.value)}
      multiline
      rows={4} // To give the user more space for the description
    />

<div style={{ marginTop: '20px', width: '100%' }}>
      <label htmlFor="upload-video" style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>
        Upload a video (30 seconds max):
      </label>
      {error1 && <Alert  severity="error">{error1}</Alert>}
      <div style={{ padding: '10px', border: '2px dashed #ccc', borderRadius: '8px' }}>
        <input
          id="upload-video"
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          style={{ width: '100%', padding: '10px', fontSize: '1rem' }}  // Wider input area
        />
      </div>

      {previewUrl && previewType === 'video' && (
        <video 
          src={previewUrl} 
          controls 
          style={{ width: '100%', marginTop: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          Your browser does not support the video tag.
        </video>
      )}
    </div>

    {/* Warning for video length */}
    <div style={{ marginTop: '10px', color: 'red', fontSize: '0.875rem' }}>
      Please upload a video no longer than 30 seconds.
    </div>
  </DialogContent>
  
  <DialogActions>
    <Button onClick={handleCloseDialog}>Cancel</Button>
    <Button onClick={handleSubmitVideo} variant="contained" color="primary">
      Submit
    </Button>
  </DialogActions>
</Dialog>
<Dialog open={openVideoDialog} onClose={handleCloseVideoDialog} maxWidth="md" fullWidth>
  <DialogTitle>{selectedVideoDate}</DialogTitle>

  <DialogContent>
    <TextField
      label="Describe your experience..."
      fullWidth
      margin="normal"
      value={selectedVideoDescription}
      onChange={(e) => setSelectedVideoDescription(e.target.value)}
    />

    {selectedVideoUrl ? (
      <Box position="relative">
        <video src={selectedVideoUrl} autoPlay loop style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}></video>
        <Button 
          variant="outlined" 
          color="error" 
          onClick={() => handleRemoveVideo()} 
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
        >
          x
        </Button>
      </Box>
    ) : (
      <div style={{ marginTop: '20px',  padding: '10px', border: '2px dashed #ccc', borderRadius: '8px' }}>
        <label htmlFor="upload-video" style={{ fontWeight: 'bold', display: 'block', marginBottom: '10px' }}>
          Upload a video (30 seconds max):
        </label>
        {error1 && <Alert  severity="error">{error1}</Alert>}
        <input 
          id="upload-video" 
          type="file" 
          accept="video/*" 
          onChange={handleFileChange} 
          style={{ width: '100%', padding: '10px', fontSize: '1rem' }} 
        />
        {previewUrl && previewType === 'video' && (
          <video 
            src={previewUrl} 
            style={{ width: '100%', marginTop: '10px', borderRadius: '8px' }} 
            autoPlay 
            loop 
          ></video>
        )}
      </div>
    )}
  </DialogContent>

  <DialogActions>
    <Button onClick={handleCloseVideoDialog}>Close</Button>
    <Button onClick={handleUpdateVideo} variant="contained" color="primary">
      Update
    </Button>
  </DialogActions>
</Dialog>
    </Box>
  );
};

export default DailyProgressDisplay;