// challenger-app/src/firebaseConfig.ts

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';

//import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyC2po5IAv-A2XO71D-IZnGBbOoJ8f02apc",
    authDomain: "auth.challexa.com",
    projectId: "challenger-staging-2024",
    storageBucket: "challenger-staging-2024.appspot.com",
    messagingSenderId: "437473152361",
    appId: "1:437473152361:web:9ed1a516a3b72e2147c1f8",
    measurementId: "G-NTVZKJ6VT5"
  };
const PUBLIC_VAPID_KEY = 'BJys4UiD837hAnl8aeCbyM4RmNW5P8soPe3yPlKi9y9ZMugqZCLCRMkgafIOIsYvJ9ClZhPLWGn8326T_QuG1JE';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);
//const analytics = getAnalytics(app);

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

  // Trigger an alert (optional)
  if(payload && payload.notification && payload.notification.title){
    alert(`New notification: ${payload.notification.title} - ${payload.notification.body}`);

    // Show browser notification if permission is granted
    if (Notification.permission === 'granted') {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: '../../public/logo192.png'
    })
  }
}})


/* deleteToken(messaging)
  .then(() => {
    console.log('Token successfully unsubscribed');
  })
  .catch((err) => {
    if (err.code === 'messaging/token-unsubscribe-failed') {
      console.log('Token not found or already unsubscribed.');
    } else {
      console.error('Error unsubscribing token: ', err);
    }
  }); */
export { auth,messaging, PUBLIC_VAPID_KEY };